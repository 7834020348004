export const dashboardData = {
  banners: [
    {
      id: "home_banner",
      image: "/Website-Header/new-resize-3.png",
      products: null,
      title: "Rent it, Own it",
    },
  ],
  favourites: [
    {
      brand: "PowerMax/Cosco/Fitkit or equivalent",
      category: "Treadmill",
      created_at: "2023-07-30T07:59:32.965Z",
      display_long_price: false,
      express_delivery_available: false,
      images: [
        "/New Design Images-v2/Category/Fitness/Motorized Treadmill - upto 120 kg-1.jpg",
        "/New Design Images-v2/Category/Fitness/Motorized Treadmill - upto 120 kg-2.jpg",
        "/New Design Images-v2/Category/Fitness/Motorized Treadmill - upto 120 kg-3.jpg",
        "/New Design Images/Category/Fitness/Treadmill-uc-1.jpg",
      ],
      like_count: 0,
      prices: [
        {
          max_period: 5,
          min_period: 3,
          original_price: 4407,
          price: 3966,
          rental_unit: "month",
        },
        {
          max_period: 9,
          min_period: 6,
          original_price: 4068,
          price: 3661,
          rental_unit: "month",
        },
        {
          max_period: 12,
          min_period: 10,
          original_price: 3814,
          price: 3432,
          rental_unit: "month",
        },
      ],
      sku: "Fitness-Treadmill-upto-120-Kg",
      thumbnail_image:
        "/New Design Images-v2/Category/Fitness/Motorized Treadmill - upto 120 kg T.jpg",
      title: "Motorized Treadmill - upto 120 kg",
      type: "fitnessEquipment",
    },
    {
      category: "Sports",
      created_at: "2022-03-05T10:31:07.004Z",
      display_long_price: true,
      express_delivery_available: false,
      images: [
        "/Other New Design Images/Bicycle 1.jpg",
        "/Other New Design Images/Bicycle 2.jpg",
      ],
      is_trending: true,
      like_count: 0,
      prices: [
        {
          max_period: 3,
          min_period: 3,
          original_price: 1229,
          price: 1106,
          rental_unit: "day",
        },
        {
          max_period: 7,
          min_period: 7,
          original_price: 1907,
          price: 1716,
          rental_unit: "day",
        },
        {
          max_period: 31,
          min_period: 31,
          original_price: 2797,
          price: 2517,
          rental_unit: "day",
        },
        {
          max_period: 3,
          min_period: 2,
          original_price: 1568,
          price: 1411,
          rental_unit: "month",
        },
        {
          max_period: 6,
          min_period: 4,
          original_price: 1314,
          price: 1182,
          rental_unit: "month",
        },
      ],
      sku: "Entertainment-Gear-Bicycle",
      thumbnail_image: "/Other New Design Images/Bicycle 1.jpg",
      title: "Gear Bicycle",
      type: "outdoors",
    },
    {
      brand: "Blue Star/Onida/Carrier/LG or equivalent",
      category: "AC",
      created_at: "2024-04-18T08:21:44.699Z",
      display_long_price: false,
      express_delivery_available: false,
      images: [
        "/New Design Images-v2/Category/Appliances/Split AC-1 ton-1.jpg",
        "/New Design Images-v2/Category/Appliances/Split AC-1 ton-2.jpg",
        "/New Design Images-v2/Category/Appliances/Split AC-1 ton-3.jpg",
        "/New Design Images-v2/Category/Appliances/Split AC-1 ton-4.jpg",
      ],
      like_count: 0,
      prices: [
        {
          max_period: 7,
          min_period: 4,
          original_price: 1200,
          price: 1200,
          rental_unit: "month",
        },
        {
          max_period: 11,
          min_period: 8,
          original_price: 1050,
          price: 1050,
          rental_unit: "month",
        },
        {
          max_period: 24,
          min_period: 12,
          original_price: 950,
          price: 1150,
          rental_unit: "month",
        },
      ],
      sku: "AC-Split-1-Ton-Rental",
      thumbnail_image:
        "/New Design Images-v2/Category/Appliances/Split AC - 1 Ton T.jpg",
      title: "Split AC - 1 Ton",
      type: "appliance",
    },
    {
      category: "Stroller",
      created_at: "2023-07-26T13:26:47.789Z",
      display_long_price: false,
      express_delivery_available: false,
      images: [
        "/Other New Design Images/Stroller-6.jpg",
        "/Other New Design Images/Stroller-4.jpg",
        "/New Design Images/Category/Kids/Stroller-6.jpg",
        "/New Design Images/Category/Kids/Stroller-4.jpg",
      ],
      like_count: 0,
      prices: [
        {
          max_period: 5,
          min_period: 4,
          original_price: 466,
          price: 419,
          rental_unit: "month",
        },
        {
          max_period: 9,
          min_period: 6,
          original_price: 381,
          price: 343,
          rental_unit: "month",
        },
        {
          max_period: 12,
          min_period: 10,
          original_price: 339,
          price: 305,
          rental_unit: "month",
        },
      ],
      sku: "Kids-Stroller-1",
      thumbnail_image: "/Other New Design Images/Stroller-7.jpg",
      title: "Adjustable Baby Stroller (upto 24 months)",
      type: "kids",
    },
  ],
  segments: [
    {
      icon: "/v2/category-static-images/appliances-icon.png",
      id: "ff3df4fg-cbce-4261-6523-556j51ee5fnm",
      image: "/v2/category-static-images-v2/appliances-img.jpg",
      is_parent: true,
      name: "Appliances",
      type: "appliance",
    },
    {
      icon: "/v2/category-static-images/fitness-icon.png",
      id: "ff3df03b-cbce-4261-9123-556j51ee5fgb",
      image: "/v2/category-static-images-v2/fitness-img.jpg",
      is_parent: true,
      name: "Fitness",
      type: "fitnessEquipment",
    },
    // {
    //   icon: "/v2/category-static-images/entertainment-icon.png",
    //   id: "ff3dfhjl-cbce-4801-6893-559l51ee5fnm",
    //   image: "/v2/category-static-images-v2/entertainment-img.jpg",
    //   is_parent: true,
    //   name: "Entertainment",
    //   tagline: "100  ways to entertain",
    //   type: "entertainment",
    // },
    // {
    //   icon: "/v2/category-static-images/kid-icon.png",
    //   id: "ff3df03b-cbce-4261-90dc-549e51ee5fgb",
    //   image: "/v2/category-static-images-v2/kid-img.jpg",
    //   is_parent: true,
    //   name: "Kids",
    //   tagline: "Only on smartown",
    //   type: "kids",
    // },
    // {
    //   icon: "/v2/category-static-images/outdoors-icon.png",
    //   id: "mm5df4fg-cbte-9891-6083-578j51egh7nm",
    //   image: "/v2/category-static-images-v2/outdoors-img.jpg",
    //   is_parent: true,
    //   name: "Outdoors",
    //   type: "outdoors",
    // },
  ],
};
