import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

//nested components end

import { RemoveTrailingSlash } from "./utils/RemoveTrailingSlash";
import Layout from "./Layout";
import { dashboardData } from "./api/dashboard";

const About = React.lazy(() => import("./pages/About"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Home = React.lazy(() => import("./pages/Home"));
const TermsAndCondition = React.lazy(() => import("./pages/TermsAndCondition"));
const ProductListing = React.lazy(() => import("./pages/ProductListing"));

const PageNotFound = React.lazy(() => import("./pages/PageNotFound"));
const Privacy = React.lazy(() => import("./pages/Privacy"));

function App() {
  return (
    <>
      <BrowserRouter>
        <RemoveTrailingSlash />
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Home
                  bannerImges={dashboardData.banners}
                  loading={false}
                  cat={dashboardData.segments}
                  favItems={dashboardData.favourites}
                />
              </Layout>
            }
          />
          {/* <Route
            path="/contact"
            element={
              <Layout>
                <Contact />
              </Layout>
            }
          /> */}
          <Route
            path="/about"
            element={
              <Layout>
                <About />
              </Layout>
            }
          />

          <Route
            path="/termconditions"
            element={
              <Layout>
                <TermsAndCondition />
              </Layout>
            }
          />
          <Route
            path="/privacypolicy"
            element={
              <Layout>
                <Privacy />
              </Layout>
            }
          />
          <Route
            path="/products/:category"
            element={
              <Layout>
                <ProductListing />
              </Layout>
            }
          />

          <Route
            path="*"
            element={
              <Layout>
                <PageNotFound />
              </Layout>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
